import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Accordion from 'react-bootstrap/Accordion';
import { PencilSquare } from 'react-bootstrap-icons';

type AddNoteProps = {
  addNote: (title: string, content: string) => void;
};

const AddNote = ({ addNote }: AddNoteProps) => {
  const [content, setContent] = React.useState('');
  const [title, setTitle] = React.useState('');

  const handleSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      addNote(title, content);
      setContent('');
      setTitle('');
    },
    [addNote, title, setTitle, content, setContent]
  );

  return (
    <Accordion className="mb-3 w-75 mx-auto" >
      <Accordion.Item eventKey="0">
        <Accordion.Header as="div">
          <h2 style={{fontSize:"1.5em"}}>
            <span style={{marginRight:'10px'}}>
              <PencilSquare/>
            </span>
            <span className="align-text-bottom">New note</span>
          </h2>
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <Col className="mx-auto">
              <Form onSubmit={handleSubmit} className="p-3 border-0">
                <FloatingLabel controlId="noteTitle" label="Title" className="mb-2">
                  <Form.Control
                    placeholder="Note title"
                    maxLength={30}
                    value={title}
                    autoComplete="off"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setTitle(e.currentTarget.value)
                    }
                  />
                </FloatingLabel>
                <FloatingLabel controlId="noteContent" label="Note" className="mb-2">
                  <Form.Control
                    as="textarea"
                    placeholder="Note content"
                    style={{ height: '150px', resize: 'none' }}
                    value={content}
                    maxLength={500}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setContent(e.currentTarget.value)
                    }
                  />
                </FloatingLabel>
                <Row>
                  <Button type="submit" className="w-50 col-6 mx-auto d-none d-sm-block">
                    Create Note
                  </Button>
                  <Button type="submit" className="w-75 col-6 mx-auto d-block d-sm-none">
                    Add Note
                  </Button>
                </Row>
              </Form>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AddNote;
