import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { EyeFill, EyeSlashFill, Trash } from 'react-bootstrap-icons';

export type NoteType = {
  id: number;
  isPublic: boolean;
  authorName: string;
  authorId: number;
  title: string;
  content: string;
  userId?: number;
};

type NoteProps = NoteType & {
  removeNote: (id: number) => void;
};

const Note = ({
  id,
  isPublic,
  authorName,
  authorId,
  title,
  content,
  userId,
  removeNote
}: NoteProps) => {
  return (
    <Card className="h-100">
      <Card.Header>
        <Row>
          <Col as="h3" xs={1}>
            {isPublic ? <EyeFill /> : <EyeSlashFill />}
          </Col>
          <Col as="h3" className="text-truncate m-0" xs={8}>
            <span className="align-text-bottom">{title}</span>
          </Col>
          <Col>
            {userId === authorId && (
              <Button
                variant="danger"
                className="float-end"
                onClick={() => removeNote(id)}
              >
                <Trash />
              </Button>
            )}
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Card.Text style={{whiteSpace: 'pre-wrap'}}>{content}</Card.Text>
      </Card.Body>
      <Card.Footer>Written by {authorName}</Card.Footer>
    </Card>
  );
};

export default Note;
