import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useAlert } from 'react-alert';
import DOMPurify from 'dompurify';
import axios from 'axios';
import Note from './Note';
import type { NoteType } from './Note';
import AddNote from './AddNote';

type NoteListProps = {
  signedIn: boolean;
  userId: number;
};

const NoteList = ({ signedIn, userId }: NoteListProps) => {
  const [notes, setNotes] = useState<NoteType[]>([]);
  const alert = useAlert();

  React.useEffect(() => {
    axios({
      method: 'get',
      url: '/api/notes'
    })
      .then((response) => {
        if (response.data.success) {
          setNotes(
            response.data.notes.map((note: NoteType) => ({
              id: note.id,
              isPublic: note.isPublic,
              authorName: note.authorName,
              authorId: note.authorId,
              title: DOMPurify.sanitize(note.title),
              content: DOMPurify.sanitize(note.content),
              userId: userId
            }))
          );
        }
      })
      .catch(() => alert.error('Error while getting note list'));
  }, [setNotes, alert, userId]);

  const removeNote = React.useCallback(
    (noteId: number) => {
      axios({
        method: 'delete',
        url: `/api/note/${noteId}`
      })
        .then((response) => {
          response.data.success
            ? setNotes(notes.filter((note) => note.id !== noteId))
            : alert.error(response.data.error || 'Error while deleting note');
        })
        .catch(() => alert.error('Error while deleting note'));
    },
    [alert, notes]
  );

  const addNote = React.useCallback(
    (title: string, content: string) => {
      axios({
        method: 'post',
        url: '/api/note',
        data: {
          content: content,
          title: title
        }
      })
        .then((response) => {
          if (response.data.success) {
            const note = response.data.note;
            setNotes((notes) => [
              ...notes,
              {
                id: note.id,
                isPublic: note.isPublic,
                authorName: note.authorName,
                authorId: note.authorId,
                title: DOMPurify.sanitize(note.title),
                content: DOMPurify.sanitize(note.content),
                userId: userId
              }
            ]);
          } else {
            alert.error(response.data.error || 'Error while adding note');
          }
        })
        .catch(() => alert.error('Error while adding note'));
    },
    [userId, setNotes, alert]
  );

  return (
    <Row className="my-3">
      <Col>
        {signedIn && <AddNote addNote={addNote} />}
        <Row xs={1} md={2} className="g-3">
          {notes.map((note) => (
            <Col key={note.id}>
              <Note {...note} removeNote={removeNote} userId={userId} />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default NoteList;
