import axios from 'axios';
import React from 'react';
import Container from 'react-bootstrap/Container';
import './App.css';
import Header from './Header';
import NoteList from './NoteList';

const App = () => {
  const [signedIn, setSignedIn] = React.useState(false);
  const [name, setName] = React.useState('');
  const [userId, setUserId] = React.useState(-1);

  React.useEffect(() => {
    axios({
      url: '/api/check-auth',
      method: 'post'
    })
      .then((response) => {
        if (response.data.success) {
          setSignedIn(true);
          setName(response.data.username);
          setUserId(response.data.id);
        }
      })
      .catch();
  }, [setSignedIn, setName, setUserId]);

  return (
    <Container>
      <Header
        signedIn={signedIn}
        name={name}
        setSignedIn={setSignedIn}
        setName={setName}
        setUserId={setUserId}
      />
      <NoteList signedIn={signedIn} userId={userId} />
    </Container>
  );
};

export default App;
