import React, { FormEvent, ReactNode, useCallback } from 'react';
import axios from 'axios';
import { useAlert } from 'react-alert';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

type HeaderProps = {
  signedIn: ReactNode;
  setSignedIn: React.Dispatch<boolean>;
  name: string;
  setName: React.Dispatch<string>;
  setUserId: React.Dispatch<number>;
};

type AccountContainerProps = {
  setSignedIn: React.Dispatch<boolean>;
  setName: React.Dispatch<string>;
  setUserId: React.Dispatch<number>;
  alert?: any;
  name?: string;
};

const SignOutButton = ({
  setSignedIn,
  setName,
  setUserId,
  alert,
  name
}: AccountContainerProps) => {
  const handleClick = useCallback(() => {
    axios({
      method: 'post',
      url: '/api/sign-out'
    })
      .then((response) => {
        if (response.data.success) {
          setName('');
          setSignedIn(false);
          setUserId(-1);
        } else {
          alert?.error('An error has occurred');
        }
      })
      .catch(() => {
        alert?.error('An error has occurred');
      });
  }, [setSignedIn, setName, setUserId, alert]);

  return (
    <Navbar.Collapse className="justify-content-end w-100">
      <Navbar.Text className="mx-3 d-none d-lg-block">Signed in as <span className="text-light">{name}</span></Navbar.Text>
      <Button variant="outline-light" onClick={() => handleClick()}>
        Sign out
      </Button>
    </Navbar.Collapse>
  );
};

const AccountContainer = ({
  setSignedIn,
  setName,
  setUserId,
  alert
}: AccountContainerProps) => {
  const [formName, setFormName] = React.useState('');
  const [formPassword, setFormPassword] = React.useState('');
  const [buttonType, setButtonType] = React.useState('');

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (buttonType === 'sign-in') {
        axios({
          method: 'post',
          url: '/api/sign-in',
          data: {
            username: formName,
            password: formPassword
          }
        })
          .then((response) => {
            if (response.data.success) {
              setName(response.data.username);
              setUserId(response.data.id);
              setSignedIn(true);
            } else {
              alert?.error(response.data.error || 'An error has occurred');
            }
          })
          .catch(() => {
            alert?.error('An error has occurred');
          });
      } else if (buttonType === 'register') {
        axios({
          method: 'post',
          url: '/api/register',
          data: {
            username: formName,
            password: formPassword
          }
        })
          .then((response) => {
            if (response.data.success) {
              setName(response.data.username);
              setUserId(response.data.id);
              setSignedIn(true);
            } else {
              alert?.error(response.data.error || 'An error has occurred');
            }
          })
          .catch(() => {
            alert?.error('An error has occurred');
          });
      }
    },
    [setSignedIn, setName, setUserId, formName, formPassword, buttonType, alert]
  );

  return (
    <Navbar.Collapse >
      <Container>
      <Form onSubmit={handleSubmit}>
        <Row xs={1} md={4} className="g-3 float-end">
          <Col md={4} className="px-1">
            <Form.Control
              type="text"
              placeholder="Username"
              value={formName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFormName(e.currentTarget.value)
              }
            />
          </Col>
          <Col md={4} className="px-1">
            <Form.Control
              placeholder="Password"
              type="password"
              value={formPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFormPassword(e.currentTarget.value)
              }
            />
          </Col>
          <Col md={2} className="px-1">
            <Button
              variant="primary"
              className="w-100"
              type="submit"
              value="Sign In"
              onClick={() => setButtonType('sign-in')}
            >
              Sign in
            </Button>
          </Col>
          <Col md={2} className="px-1">
            <Button
              variant="outline-light"
              className="w-100"
              type="submit"
              value="Register"
              onClick={() => setButtonType('register')}
            >
              Register
            </Button>
          </Col>
        </Row>
      </Form>
      </Container>
    </Navbar.Collapse>
  );
};

const Header = ({
  signedIn,
  setSignedIn,
  name,
  setName,
  setUserId
}: HeaderProps) => {
  const alert = useAlert();
  return (
    <Row>
      <Navbar variant="dark" bg="dark" expand="lg" className="py-3 rounded-bottom">
        <Container>
          <Navbar.Brand>
            <h1 style={{ fontSize: '1.5em' }}>Take Note</h1>
          </Navbar.Brand>
          <Navbar.Toggle/>
          {signedIn ? (
            <SignOutButton
              setSignedIn={setSignedIn}
              setName={setName}
              setUserId={setUserId}
              alert={alert}
              name={name}
            />
          ) : (
            <AccountContainer
              setSignedIn={setSignedIn}
              setName={setName}
              setUserId={setUserId}
              alert={alert}
            />
          )}
        </Container>
      </Navbar>
    </Row>
  );
};

export default Header;
